<template>
    <v-container v-if="editLoader" type="image" class="loding">
        <v-skeleton-loader type="image"></v-skeleton-loader>
        <v-skeleton-loader type="image"></v-skeleton-loader>
      </v-container>
    <div v-else 
    scrollable
    size="xl"
    class="detaildevisModale modal-test my-5 mx-3 bg-color p-5"
  >
     
  
     <div v-if="devisObject.data?.devis" class="modal-test ">
      <div>
        <div class="d-flex justify-content-between mb-5">
          <v-chip
            class="py-4 px-4 brouillon"
            v-if="devisObject.data.brouillon === 1"
            style="background: #e4261b; color: white"
          >
            {{ $t("DRAFT") }}
          </v-chip>
          <v-chip
            class="py-3 px-4 brouillon"
            v-if="devisObject.data.brouillon === 0"
            style="background: #f8f8f8"
          >
            {{ $t("SIGNA") }}
          </v-chip>
          <v-chip
          v-if="devisObject.data?.devis?.is_signed == 'pending'"
          style="background: #ffb300"
        >
          {{ $t("PENDING") }}
        </v-chip>
          <div>
            <b-dropdown
              size="lg"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              class="custom-dropdown"
            >
              <template #button-content style="box-shadow: none !important">
                <span style="color: black; font-size: 24px">&#x22EE;</span>
                <span class="sr-only">Menu</span>
              </template>
              <b-dropdown-item
                v-if="
                  isSuperAdmin ||
                  isentrepriseAdmin ||
                  isAssistant ||
                  isCommercial
                "
                @click="generate()"
                >Telecharger</b-dropdown-item
              >
              <b-dropdown-item href="#">facture</b-dropdown-item>
              <b-dropdown-item
                v-if="
                  isSuperAdmin ||
                  isentrepriseAdmin ||
                  isAssistant ||
                  isCommercial
                "
                @click="sendEmail()"
              >
                {{ $t("SEND_BY_EMAIL") }}</b-dropdown-item
              >
              <b-dropdown-item
                v-if="
                  isSuperAdmin ||
                  isentrepriseAdmin ||
                  isAssistant ||
                  isCommercial
                "
                @click="signed()"
                >{{ $t("YOUSIGN") }}</b-dropdown-item
              >
            </b-dropdown>
            <b-button
              variant="success"
              @click="handleUpdate()"
              v-if="
                isSuperAdmin || isentrepriseAdmin || isAssistant || isCommercial
              "
            >
              {{ $t("EDIT") }}
              <div v-if="editLoader" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </b-button>
          </div>
        </div></div>
      <div class="">
       <h5>{{ $t("DETAILS_DEVIS") }} {{ devisObject.data?.devis?.numerodevis }}</h5>
        <div class="part justify">
          <div class="divborder py-3">
            <p>{{ $t("COORDONNEES_DEVIS") }}</p>
          </div>
          <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-12">
              <div class="block">
                <div class="d-flex">
                  <span class=""
                    >{{ $t("Numéro client") }}:
                    {{ devisObject.data?.client?.num_client }}
                  </span>
  
                </div>
                <div class="d-flex">
                  <span class="">{{ $t("DATE_VISITE_TECHNIQUE") }}:</span>
                  <span>
                    {{ formatDate(devisObject.data?.devis?.visite_technique) }}</span
                  >
                </div>
                <div class="d-flex">
                  <span class="">{{ $t("DATE_START_DEVIS") }}:</span>
                  <span> {{ formatDate(devisObject.data?.devis?.debut_devis) }} </span>
                </div>
                <div class="d-flex">
                  <span class="">{{ $t("DATE_END_DEVIS") }}:</span>
                  <span> {{ formatDate(devisObject.data?.devis?.fin_devis) }} </span>
                </div>
                <div class="d-flex">
                  <span class="">{{ $t("Adresse des travaux") }}:</span>
                  <span>
                    {{ devisObject.data?.devis?.rue_travaux }}
                    {{ devisObject.data?.devis?.ville_travaux }}
                    {{ devisObject.data?.devis?.cp_travaux }}
                  </span>
                </div>
                <div>
                  parcelle cadastrale:
                  {{
                    devisObject.data.devis?.logement?.parcelle_cadastrale
                  }}
                </div>
              </div>
            </div>
  
            <div class="col-lg-4 col-md-4 col-sm-12">
              <ul>
                <span class="info">
                  {{ devisObject.data?.devis?.client?.rue }}
                </span>
                <span class="info">
                  {{ devisObject.data?.devis?.client?.cp }}
                  {{ devisObject.data?.devis?.client?.ville }}
                </span>
                <div>
                  <span class="entrepriseinfo">{{ $t("TEL") }} : </span>
                  <span>
                    {{ devisObject.data.devis?.client?.phone.prefix }}
                    {{ devisObject.data.devis?.client?.phone.phone_number }}
                  </span>
                </div>
                <div>
                  <span class="entrepriseinfo">{{ $t("EMAIL") }} : </span>
                  <span>
                    {{ devisObject.data?.devis?.client?.email }}
                  </span>
                </div>
                <div>
                  <span class="entrepriseinfo">{{ $t("ZONE") }} : </span>
                  <span>{{ devisObject.data?.devis?.client?.zone }} </span>
                </div>
                <div>
                  <span class="entrepriseinfo"
                    >{{ $t("Type de chauffage") }} :
                  </span>
                  <span>
                    {{ devisObject.data?.devis?.logement?.type_chauffage }}
                  </span>
                </div>
                <div>
                  <span class="entrepriseinfo"
                    >{{ $t("Type de logement") }} :
                  </span>
               
                  <span>
                    {{ devisObject.data?.devis?.logement?.type }}
                  </span>
                  <span v-if="devisObject.data?.devis?.logement?.ancienter===1"
                    >/Logement neuf
                    </span >
                    
                    <span v-if="devisObject.data?.devis?.logement?.ancienter===2"
                    >/Moins de 2 ans
                    </span >
                    <span v-if="devisObject.data?.devis?.logement?.ancienter===3"
                    >/Plus de 2 ans et moins de 15 ans
                    </span >
                    <span v-if="devisObject.data?.devis?.logement?.ancienter===15"
                    >/Plus de 15 ans
                    </span >
                  <span
                    >/{{
                      devisObject.data?.devis?.logement?.surface
                    }}
                    m²</span
                  >
                  </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
     
      <div class="divborder py-4">
        <p>{{ $t("DETAILS_DEVIS") }}</p>
      </div>
     
    <div v-if="devisObject.data.devis?.type_devis==='BAR-TH-101'">
        <div>
          BAR-TH-101 : Mis en page d'un chauffe-eau solaire individuel(CESI),
          Marque <b> {{ devisObject.data.devis?.chauffe_eau?.marque }}</b
          >, Référence
          <b> {{ devisObject.data.devis?.chauffe_eau?.reference }}</b>
        </div>
        <div>
          Les capteurs solaire ont une certification
          <b>{{ devisObject.data.devis?.chauffe_eau?.nom_certification }}</b>
          reposant sur la norme
          <b> {{ devisObject.data.devis?.chauffe_eau?.norme }}</b
          >.
        </div>
        <div>
          La nature du fluide circulant dans les capteurs :<b>{{
            devisObject.data.devis?.chauffe_eau?.nature_fluide
          }}</b>
        </div>
        <div>
          <b>Note : Les capteurs solaires ne sont pas des capteurs hybrides.</b>
        </div>
      </div>
      <div v-if="devisObject.data.devis?.type_devis==='BAR-TH-112'">
        <div>
          BAR-TH-112 : Installation d'un appareil indépendant de chauffage au bois 
          <div><b>{{ devisObject.data.devis?.chauffage_au_bois?.type}}</b> fonctionnant au bois <b v-if="devisObject.data.devis?.chauffage_au_bois?.bois_granule">sous forme de granulés</b>
            <b v-else>autre forme de granulés</b>.</div>
          <div v-if="devisObject.data.devis?.chauffage_au_bois?.flammeverte"><b>L'appareil possède le label flamme verte 7*</b></div>
      <div v-if="!devisObject.data.devis?.chauffage_au_bois?.flammeverte">-CO: <b>{{ devisObject.data.devis?.chauffage_au_bois?.emissions_CO}}%</b></div>
      <div v-if="!devisObject.data.devis?.chauffage_au_bois?.flammeverte">-Nox: <b>{{ devisObject.data.devis?.chauffage_au_bois?.emissions_NOx}}mg</b></div>
      <div v-if="!devisObject.data.devis?.chauffage_au_bois?.flammeverte">-rendement (nominale) <b>{{ devisObject.data.devis?.chauffage_au_bois?.rendement_nominal}} KW</b></div>
      <div v-if="!devisObject.data.devis?.chauffage_au_bois?.flammeverte">-émmission de particules: <b> {{ devisObject.data.devis?.chauffage_au_bois?.emissions_particules}}mg/Nm³</b></div>
       Marque <b> {{ devisObject.data.devis?.chauffage_au_bois?.marque }}</b>, Référence
          <b>{{ devisObject.data.devis?.chauffage_au_bois?.reference }}</b>
        </div>
        <div>
          Le rendement énergétique et les émissions de polluants ont été mesurés selon la norme
          <b>{{ devisObject.data.devis?.chauffage_au_bois?.normes}}</b
          >.
        </div>
         <div><b>Note : Les capteurs solaires ne sont pas des capteurs hybrides.</b></div>
         
      </div>
      <div v-if="devisObject.data.devis?.type_devis==='BAR-TH-113'">
        <div>
          BAR-TH-113 : Mis en page d'une chaudière biomasse ligneuse individuelle à
          <div v-if="devisObject.data.devis?.chaudiere_biomasse?.type_alimentation==='automatique'">
            alimentation  <b>{{devisObject.data.devis?.chaudiere_biomasse?.type_alimentation}}</b>, associée à un silo de stockage des granulés d'un volume
          <b>{{devisObject.data.devis?.chaudiere_biomasse?.volume}}</b> litres.
          </div>
          <div v-if="devisObject.data.devis?.chaudiere_biomasse?.type_alimentation==='manuel'">
            alimentation <b> {{devisObject.data.devis?.chaudiere_biomasse?.type_alimentation}}</b>, <b v-if="devisObject.data.devis?.chaudiere_biomasse?.ballon_existant=== 1">associée à un ballon tampon </b>
            <b v-if="devisObject.data.devis?.chaudiere_biomasse?.ballon_existant  === 0">n'est pas associée à un ballon tampon </b>
          </div>
  
           Marque <b> {{devisObject.data.devis?.chaudiere_biomasse?.marque}}</b>, Référence <b> {{devisObject.data.devis?.chaudiere_biomasse?.reference}}</b>
          
         </div>
         <div>Efficacité saisonnière <b>{{devisObject.data.devis?.chaudiere_biomasse?.efficacite_energetique}}%</b>, selon le réglement (EU) 2015/1189 de la Commission du
         28 avril 2015</div>
         <div>Puissance thermique nominale <b>{{devisObject.data.devis?.chaudiere_biomasse?.puissance}} kW</b>.</div>
         <div v-if="devisObject.data.devis?.chaudiere_biomasse?.flammeverte === 1">Label flamme verte <b>7*</b></div>
         <div v-if="devisObject.data.devis?.chaudiere_biomasse?.flammeverte === 0">
         <div> Les émissions saisonnière de particules sont {{devisObject.data.devis?.chaudiere_biomasse?.emissions_particules}}  mg/Nm3</div>
         <div>Les émissions saisonnière de monoxyde de carbone (CO) sont {{devisObject.data.devis?.chaudiere_biomasse?.emissions_CO}} mg/Nm3</div>
         <div>Ls émissions saisonnière d'oxydes d'azote (NOx) sont {{devisObject.data.devis?.chaudiere_biomasse?.emissions_NOx}} mg/Nm3</div>
         <div>Les émissions saisonnière de composés organiques gazeux sont {{devisObject.data.devis?.chaudiere_biomasse?.emissions_saisonère}} mg/Nm3</div>
         </div>
         <div>Classe du régulateur <b>{{devisObject.data.devis?.chaudiere_biomasse?.classe}}</b></div>    
        <div>- Absence de plaque signalétique de la chaudière à remplacer.</div>
        
      </div>
      <div v-if="devisObject.data.devis?.type_devis==='BAR-TH-143'">
        <div>
          BAR-TH-143 :,
          <div>La surface chauffée est de <b>{{devisObject.data.devis?.systeme_solaire?.surface_chaufe}} m²</b></div>
         <div
          v-for="(chantierItem, index) in devisObject.data
            .GroupeLigneDocument_produit"
          :key="index"
        >
          <div>
            <div v-if="chantierItem.type_ligne === 'produit'">
              <div>
                Marque :
        {{ chantierItem.fabricant}} 
              </div>
              <div>
                Réf :
  {{ chantierItem.ref}}
              </div>
            </div>
          </div>
        </div>  
        </div>
          <div>
            Les capteurs solaire ont une certification <b>{{devisObject.data.devis?.systeme_solaire?.nom_certification}}</b> reposant sur la norme <b>{{devisObject.data.devis?.systeme_solaire?.norme}}</b>.
          </div>
          <div>La productivité des capteurs, calculée avec un rayonnement de 1000W/m²: <b>{{devisObject.data.devis?.systeme_solaire?.productivite}} W/m²</b></div>
          <div>
           Nature du fluide capteurs :<b>{{devisObject.data.devis?.systeme_solaire?.nature_fluide}}</b>
          </div>
          <div>La surface hors tout de capteurs installés:<b>{{devisObject.data.devis?.systeme_solaire?.surface_hors_tout}} m²</b></div>
        <div>Les capteurs sont associés à un ou plusieurs ballon(s) d'eau chaude solaire(s) avec </div>
        <div>une capacité de stockage de <b>{{devisObject.data.devis?.systeme_solaire?.capacite_stockage}} L</b>, et une efficacité énergétique de classe <b>{{devisObject.data.devis?.systeme_solaire?.classe_efficacite_energetique}}</b></div>
        déterminée conformément au règlement (UE) no 812/2013 de la Commission du 18 février 2013
        
      </div>
      <div  v-if="devisObject.data.devis?.type_devis==='BAR-TH-171'">
        <div>
          BAR-TH-171 : Mis en page d'une pompe à chaleur de type air/eau,
          <b>{{
            devisObject.data.devis?.systeme_chauffage?.denomination_temperature
          }} </b
          >temperature.
        </div>
        <div>
          Le coefficient de performance (COP) est de :
          <b>{{
            devisObject.data.devis?.systeme_chauffage?.coefficient_performance
          }}</b>
        </div>
        <div>
          L'efficacité énergitique saisonnière est de :
          <b>{{ devisObject.data.devis?.systeme_chauffage?.efficacite }} %</b>
          calculée selon le règlement (EU)
        </div>
        <div>n°813/2013 de la commission du 2 aout 2013</div>
        <div>
          La surface chauffée par la PAC est de
          <b>{{ devisObject.data.devis?.systeme_chauffage?.surface_chauffee }}</b>
        </div>
        <div
          v-for="(chantierItem, index) in devisObject.data
            .GroupeLigneDocument_produit"
          :key="index"
        >
          <div>
            <div v-if="chantierItem.type_ligne === 'produit'">
              <div>
                Marque :
        {{ chantierItem.fabricant}} 
              </div>
              <div>
                Réf :
  {{ chantierItem.ref}}
              </div>
            </div>
          </div>
        </div>
        <div>
          Mise en place d'un régulateur de classe :
          <b>{{ devisObject.data.devis?.systeme_chauffage?.regulateur_classe }}</b>
        </div>
        <div></div>
        <ul>
          <li>
            Dépose et remplacement d'une chaudière
            <b>{{devisObject?.data?.devis?.systeme_chauffage?.equipement_deposee}}</b>
          </li></ul>
      </div>
      <div  v-if="devisObject.data.devis?.type_devis==='BAR-EN-103'">
        <div>
          BAR-EN-103 :Mise en place d’un doublage isolant sur/sous bas sur un sous-sol non chauffé, sur vide sanitaire ou sur un passage ouvert.
    
          Marque <b> {{ devisObject.data.devis?.isolation_plancher?.marque }}</b
          >, Référence
          <b>{{ devisObject.data.devis?.isolation_plancher?.reference }}</b>
        </div>
        <div>
          La résistance thermique pour les isolants 
    
          <b>{{
            devisObject.data.devis?.isolation_plancher?.type_isolant
          }}</b>
          est évaluée selon la norme
          <b>{{ devisObject.data?.devis?.isolation_plancher?.norme }}</b>.
        </div>
        <div>Nécessaire de protéger les matériaux d'isolation thermique contre les transferts d'humidité par <b>{{ devisObject.data?.devis?.isolation_plancher?.necessite_pare_vapeur === 1 ? 'un autre dispositif que le Pare-vapeur' : 'Pare-vapeur'}}</b> afin de garantir la performance de l'ouvrage.</div>
        <div>
          L’épaisseur est<b>{{
            devisObject.data.devis?.isolation_plancher?.epaisseur
          }}</b>
          et la surface d’isolant installé est <b>{{
              devisObject.data.devis?.isolation_plancher?.surface_isolant
            }}</b>
        </div>
        <div>
          - la résistance thermique de l'isolation mise en place évaluée est 
          <b>{{
              devisObject.data.devis?.isolation_plancher?.resistance_thermique
            }}</b>
        </div>
      </div>
    <div v-if="devisObject.data.devis?.type_devis==='BAR-EN-101'">
      <div>
        BAR-EN-101 :Mise en place d’une isolation thermique {{ devisObject.data.devis?.isolation_combles_toiture?.type_pose}},
        Marque <b> {{ devisObject.data.devis?.isolation_combles_toiture?.marque }}</b
        >, Référence
        <b>{{ devisObject.data.devis?.isolation_combles_toiture?.reference }}</b>
      </div>
      <div>
        La résistance thermique pour les isolants 
  
        <b>{{
          devisObject.data.devis?.isolation_combles_toiture?.type_isolant
        }}</b>
        est évaluée selon la norme
        <b>{{ devisObject.data?.devis?.isolation_combles_toiture?.norme }}</b>.
      </div>
      <div> 
          Le type de protection contre la vapeur est<b>{{ devisObject.data?.devis?.isolation_combles_toiture?.necessite_pare_vapeur === 1 ? 'Barrière vapeur' : 'Pare-vapeur'}}</b>
        .</div>
      <div>
        L’épaisseur est<b>{{
          devisObject.data.devis?.isolation_combles_toiture?.epaisseur
        }}</b>
        et la surface d’isolant installé est <b>{{
            devisObject.data.devis?.isolation_combles_toiture?.surface_isolant
          }}</b>
      </div>
      <div>
        - la résistance thermique de l'isolation mise en place évaluée est 
        <b>{{
            devisObject.data.devis?.isolation_combles_toiture?.resistance_thermique
          }}</b>
      </div>
    </div>
  <div v-if="devisObject.data.devis?.type_devis==='BAR-EN-102'">
    <div>
      BAR-EN-102 :Mise en place d’une isolation sur mur(s) ,
      Marque <b> {{ devisObject.data.devis?.isolation_murs?.marque }}</b
      >, Référence
      <b>{{ devisObject.data.devis?.isolation_murs?.reference }}</b>
    </div>
    <div>
      La résistance thermique pour les isolants 

      <b>{{
        devisObject.data.devis?.isolation_murs?.type_isolant
      }}</b>
      est évaluée selon la norme
      <b>{{ devisObject.data?.devis?.isolation_murs?.norme }}</b>.
    </div>
    <div>
      L’épaisseur est<b>{{
        devisObject.data.devis?.isolation_murs?.epaisseur
      }}</b>
      et la surface d’isolant installé est <b>{{
          devisObject.data.devis?.isolation_murs?.surface_isolant
        }}</b>
    </div>
    <div>
      - la résistance thermique de l'isolation mise en place évaluée est 
      <b>{{
          devisObject.data.devis?.isolation_murs?.resistance_thermique
        }}</b>
    </div>
  </div>
      <div>- Kwh Cumac : <b >{{ devisObject.data.devis?.cumac }}</b></div>
      
       
      <div>
        Prime CEE <b>{{ devisObject.data.devis.montant_cee }} € </b>
      </div>
  
      <div
        v-if="
          devisObject.data.groupeLigneDocument[0]?.sous_traitant !== null
        "
      >
        Traveaux sous-traités auprès de l'entreprise
        <b>
          {{
            devisObject.data.groupeLigneDocument[0]?.sous_traitant?.name
          }}</b
        >
      </div>
      <div v-else>
        Matériel(s) fourni(s) et mis en place par notre société
        <b> {{ devisObject.data.devis.entreprise?.lib }}</b>
      </div>
      <div
        v-if="
          devisObject.data.groupeLigneDocument[0]?.sous_traitant !== null
        "
      >
        représentée par
        {{
          devisObject.data.groupeLigneDocument[0]?.sous_traitant?.name
        }}
        , SIRET
        <b>
          {{
            devisObject.data.groupeLigneDocument[0]?.sous_traitant
              ?.num_siret
          }}
          ,</b
        >
        Certificat rge
      </div>
      <div v-else>
        représentée par
        {{ devisObject.data.devis.entreprise?.lib }} , SIRET
        <b>
          {{ devisObject.data.devis.entreprise?.siret }}
          ,</b
        >
        Certificat rge
      </div>
      <div>
        Numéro
        <b> {{devisObject.data?.devis?.certificat_rge?.num}} </b>
        attribué le
        <b> {{formatDate(devisObject.data?.devis?.certificat_rge?.date_attribution)}} </b> valable
        jusqu'au <b>{{formatDate(devisObject.data?.devis?.certificat_rge?.date_expiration)}}</b
        >, Assurance
      </div>
      <div>
        civile
        <b>
          N° {{ devisObject.data?.devis?.entreprise?.num_assurance }}</b
        >
      </div>
      <ul>
        <li>
          Date de la visite technique :<b>
            {{ formatDate(devisObject.data?.devis?.visite_technique) }}</b
          >
        </li>
      </ul>
      <div
        v-for="(groupe, x) in devisObject.data?.groupeLigneDocument"
        :key="x"
        class="group"
      >
      
        <div class="part" >
         
          <table class="table">
            <thead>
              <tr>
                <th style="border-radius: 5px 0px 0px 0px" scope="col">
                  {{ $t("REF") }}
                </th>
                <th>{{ $t("WORDING") }}</th>
                <th>{{ $t("DESC") }}</th>
                <th>{{ $t("QTE") }}</th>
              
                <th>{{ $t("PUHT") }}</th>
                <th>
                  {{ $t("TOTALHT") }}
                </th>
              
              
                <th style="border-radius: 0px 5px 0px 0px">
                  {{ $t("VAT") }}
                </th>
              </tr>
            </thead>
            <tbody
            v-for="(product, y) in devisObject.data?.GroupeLigneDocument_produit"
            :key="y"
          >
            <tr>
              <td>{{ product.ref ? product.ref : "-" }}</td>
              <td class="blocklibdesc">
                <div class="libblock">
                  <!-- Affiche la lib avec un retour à la ligne après chaque 27 caractères -->
                  <div v-for="(line, index) in breakText(product.lib)" :key="'lib-' + index">
                    {{ line }}
                  </div>
                </div>
              </td>
              <td>
                <div class="descblock" v-if="product.desc">
                  <!-- Affiche la description avec un retour à la ligne après chaque 27 caractères -->
                  <div v-for="(line, index) in breakText(product.desc)" :key="'desc-' + index">
                    {{ line }}
                  </div>
                </div>
              </td>
              <td>{{ product.quantite }} {{ product.type_unite }}</td>
              <td>{{ product.unit_price }}</td>
              <td>{{ (product.unit_price * product.quantite) }}</td>
              <td>{{ product.tva }} %</td>
            </tr>
          </tbody>
          
          
            <!-- <tbody
            v-for="(
              product, y
            ) in devisObject.data?.GroupeLigneDocument_produit"
            :key="y"
          >
            <tr
              v-if="
                groupe.id == product.groupelignedocument_id &&
                readActivated[y]
              "
            >
              <td>{{ product.ref ? product.ref : "-" }}</td>
              <td class="blocklibdesc">
                <div class="libblock">
                  {{ product.lib ? product.lib : "" }}
                </div>
               
              </td>
              <td>
                <div
                class="descblock"
                v-if="product.desc"
                :class="{ 'auto-heigth': readActivated[y].bool }"
              >
                {{
                  readActivated[y].bool
                    ? product.desc
                    : product.desc.slice(0, 105)
                }}
                <span
                  class="read"
                  v-if="product.desc"
                  @click="
                    readActivated[y].bool = !readActivated[y].bool
                  "
                >
                  ...{{
                    readActivated[y].bool ? $t("LESS") : $t("MORE")
                  }}
                </span>
              </div>
              </td>
              <td>{{ product.quantite}} {{ product.type_unite }}</td>
              <td>{{ product.unit_price  }}</td>
              <td>
                {{ (product.unit_price * product.quantite) }}
              </td>
              <td>{{ product.tva }} %</td>
            </tr>
          </tbody> -->
          </table>
          <div class="flexEnd">
            <div class="sousTraitant">
              <p v-if="groupe.sous_traitant">
                Sous-traitant: {{ groupe.sous_traitant.name }}, SIRET:
                {{ groupe.sous_traitant.num_siret }}, Num RGE:
                {{ groupe.sous_traitant.num_rge }}
              </p>
            </div>
          
          </div>
        </div>
      </div>
      <div class="part">
        <div><b>Termes et conditions CEE</b></div>
        <div v-if="devisObject.data.devis?.mandataire">
          <p>{{devisObject.data.devis?.mandataire?.paragraphCEE}}</p>
          <p>{{devisObject.data.devis?.mandataire?.paragraphMaPrimeRenov}}</p>
          </div>
          <div v-if="!devisObject.data.devis?.mandataire">
            <p>{{devisObject.data.devis?.partenaire?.paragraphCEE}}</p>
            <p>{{devisObject.data.devis?.partenaire?.paragraphMaPrimeRenov}}</p>
            </div>
        <p>
          Les travaux ou prestations objet du présent document donneront lieu à
          une contribution financiére de
          {{ devisObject.data.devis?.partenaire?.nom }} (SIRET
          {{ devisObject.data.devis?.partenaire?.siret }}), versée par
          {{ devisObject.data.devis?.partenaire?.nom }} dans le cadre de
          son role actif et incitatif sous forme, directement ou via son
          mondataire {{ devisObject.data.devis?.mandataire?.nom }} sous
          réserve de l'engagement de fournir excclusivement à
          {{ devisObject.data.devis?.partenaire?.nom }} les documents
          nécessaires à la valorisation des opérations au titre du dispositif
          des Certificats d'Economies d'Energie et sous réserve de la validation
          de l'éligibilité du dossier par
          {{ devisObject.data.devis?.partenaire?.nom }} puis par
          l'autorité administrative compétente. Le montant de cette contribution
          financiére, hors champ d'application de la TVA, est susceptible de
          varier en fonction des travaux effectivement réalisé et du volume des
          CEE attribués à l'opération et est estimé à 4 000,00 € (*)
        </p>
        <div></div>
        <p>
          Le client accepte que {{ onlineUser.entreprise.lib }} collecte et
          traite ses données à caractére personnel pour les besoins du dépot
          d'un dossier CEE sur le registre EMMY conformément aux dispositions
          réglementaires en vigueur et qu'il communique ces données à
          {{ devisObject.data.devis?.partenaire?.nom }} à des fins de
          controle de la conformité des operations réalisées chez le client
          {{ devisObject.data.devis?.partenaire?.nom }}
          s'engage à respecter la réglementation française et européenne
          relative à la protection des données à caractére personnel Le
          bénéficiaire renconnait avoir pris connaissance et accepter les
          conditions générales de vente figurant ci-jointes.
        </p>
        <div>
          <div class="cadre row">
            <div class="col-8">
              <div>
                Apposer signature précédée de la mention
                <b>"Bon pour accord"</b>
              </div>
              <div>Le :</div>
            </div>
            <div class="col-4 p-2">
              <div class="flexEndTotale">
                <div class="subtableTotale">
                  <table class="completTotale">
                    <tr>
                      <td class="theadTotale">
                        {{ $t("TOTAL_HT") }}
                      </td>
                      <td>{{ devisObject.data?.total_HT }}</td>
                    </tr>
                   
                   
                    <template v-if="devisObject.data?.tva_document">
                      <tr
                        v-for="tva in devisObject.data?.tva_document"
                        :key="'tva' + tva.id"
                      >
                        <td class="theadTotale">TVA ({{ tva.tva }}%)</td>
                        <td style="border-radius: 0px 5px 0px 0px">
                          {{ tva.tva_montant }}
                        </td>
                      </tr>
                    </template>
                    <tr>
                      <td class="theadTotale">
                        {{ $t("Total TTC") }}
                      </td>
                      <td>{{ devisObject.data?.total_TTC  }}</td>
                    </tr>
                 
          
                    <tr v-if="devisObject.data?.devis?.total_to_pay">
                      <td class="theadTotale">
                        {{ $t("NET à payer") }}
                      </td>
                      <td>{{ devisObject.data?.devis?.total_to_pay }}</td>
                    </tr>
                    <tr >
                      <td class="theadTotale">PrimeCEE *</td>
                      <td>
                        {{ devisObject.data?.devis?.montant_cee }} €
                      </td>
                    </tr>
                    <tr >
                      <td class="theadTotale">Reste à payer</td>
                      <td >{{ this.calculeRest() }} €</td>
                    </tr>
                  </table>
                </div>
              </div>
              
  
             
            </div>
          </div>
        </div>
      </div>
     
      <div class="part">
        <div>
          Mode de paiement :
          <div v-if="moyens_paiement.length === 0">
            Aucun moyen de paiement disponible.
          </div>
          <div v-else>
            <span v-for="(paiement, index) in moyens_paiement" :key="paiement.id">
              <b v-if="paiement.moyen_paiement === 'Organisme_de_financement'">Organisme de financement</b>
              <b v-if="paiement.moyen_paiement === 'cheque_virement_espece'">Chèque virement espèce</b>
              <b v-if="paiement.moyen_paiement === 'Certificat_Economie_energie'">Certificat d'Économie d'énergie (CEE)</b>
              <b v-if="paiement.moyen_paiement === 'Ma_prime_renove'">Ma prime rénove (MPR)</b>
              
              <span v-if="index < moyens_paiement.length - 1"> / </span>
            </span>
          </div>
        </div>
  
        <h3
          v-if="
            +devisObject.data?.devis?.montant_maprimrenov &&
            devisObject.data?.devis?.with_aide
          "
        >
          <span class="part-title"> {{ $t("MA_PRIME_RENOV") }}: </span>
  
          <span>{{
            devisObject.data?.devis?.montant_maprimrenov | financial
          }}</span>
        </h3>
       
      </div> 
    </div>
  
    <div>
    
     
    </div>
  </div>
  </template>
  <script>
    import moment from 'moment';
     import domain from "@/environment";
  import { domains } from "@/environment";
  import { mapActions, mapGetters,mapState } from "vuex";
  import axios from "axios";
  export default {
    data() {
      return {
        readActivated: [
        {
          bool: false,
        },
      ],
        moyens_paiement:[],
        signedLoader: null,
        downloadLoader: false,
        pdfInfo: null,
        rest: null,
        downloadUrl: domains.download,
        editLoader: false,
        devisObject: {
          data: {
            devis: {
              brouillon: 1,
              product_to_delete: [],
              groups_to_add: [
                {
                  type_traveaux_id: 1,
                  type_piece_id: 1,
                  piece: "ss",
                  traveaux: "FIC",
                  produits: [],
                },
              ],
            },
          },
        },
        devisObjectLoaded: false,
      };
    },
    computed: {
      ...mapState({
        token: (state) => state.token,
      }),
      ...mapGetters(["devisObjectObject", "getOnlineUser","getcreationEntreprise"]),
      isSuperAdmin() {
        return this.getOnlineUser.role == "user.super-admin";
      },
      isentrepriseAdmin() {
        return this.getOnlineUser.role == "user.entreprise-admin";
      },
      isAssistant() {
        return this.getOnlineUser.role == "user.assistant";
      },
      isCommercial() {
        return this.getOnlineUser.role == "user.commercial-entreprise";
      },
      isClient() {
        return this.getOnlineUser.role == "user.client";
      },
      onlineUser() {
        return this.getOnlineUser;
      },
    },
    watch: {
      devisObject: {
        handler() {
         
        },
        deep: true,
      },
    },
    methods: {
      ...mapActions([
        "updatedevis",
        "getoneMonodevis",
        "downloadDevis",
        "email_devis",
        "signed_devis",
        "downloadDevis",
        "email_devis",
        "updateCreationEntreprise"
      ]),
      breakText(text) {
    const chunkSize = 27; // Nombre de caractères avant le retour à la ligne
    const regex = new RegExp(`.{1,${chunkSize}}`, 'g'); // Expression régulière pour couper après chaque 27 caractères
    return text.match(regex) || [];
  },
  
  
      calculeRest() {
       const res=(
          this.devisObject.data.total_TTC  -
          this.devisObject.data.devis.montant_cee
        );
        return res.toFixed(3)
      },
      sendEmail() {
        this.email_devis(this.devisObject.data.devis.id)
          .then(() => {
            this.box = "";
            this.$bvModal.msgBoxOk(this.$t("INVOICE_SENDED"), {
              title: "Confirmation",
              size: "sm",
              buttonSize: "sm",
              okVariant: "success",
              headerClass: "p-2 border-bottom-0",
              centered: true,
            });
          })
          .catch(() => {});
      },
      showMsgBox() {
        this.box = "";
        this.$bvModal
          .msgBoxConfirm(`${this.$t("YOUSIGN_SENDED_WARNIN")}`, {
            title: `${this.$t("CONFIRM")}`,
            size: "sm",
            buttonSize: "sm",
            okVariant: "success",
            cancelVariant: "danger",
            okTitle: `${this.$t("YES")}`,
            cancelTitle: `${this.$t("NO")}`,
            footerClass: "p-2",
            hideHeaderClose: false,
          })
          .then((value) => {
            if (value) {
              this.signed_devis({
                id: this.devisObject.data.devis.id,
                doc: this.devisObject.data.id,
              })
                .then(() => {
                  this.box = "";
                  this.$bvModal.msgBoxOk(this.$t("YOUSIGN_SENDED"), {
                    title: "Confirmation",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "success",
                    headerClass: "p-2 border-bottom-0",
                    centered: true,
                  });
                })
                .catch(() => {
                  this.erreur = this.getErreur;
                });
            }
          });
      },
      generate(devis) {
        this.downloadLoader = true;
        var id = 0;
        if (!devis) {
          id = this.devisObject.data.devis.id;
        } else {
          id = devis.devis.id;
        }
        this.downloadDevis(id)
          .then((response) => {
            this.downloadLoader = false;
            this.pdfInfo = response;
            this.download();
          })
          .catch(() => {
            this.downloadLoader = false;
          });
      },
      async signed() {
        if (this.devisObject.data.is_signed != "pending") {
          this.signedLoader = true;
          await this.signed_devis({
            id: this.devisObject.data.devis.id,
            doc: this.devisObject.data.id,
          })
            .then(() => {
              this.box = "";
              this.signedLoader = false;
              this.$bvModal.msgBoxOk(this.$t("YOUSIGN_SENDED"), {
                title: "Confirmation",
                size: "sm",
                buttonSize: "sm",
                okVariant: "success",
                headerClass: "p-2 border-bottom-0",
                centered: true,
              });
            })
            .catch(() => {
              this.signedLoader = false;
              this.erreur = this.getErreur;
            });
        } else {
          this.showMsgBox();
        }
      },
      handleUpdate() {
  this.editLoader = true;

  this.getoneMonodevis(this.devisObject.data.devis.id)
    .then(() => {
      this.editLoader = false;

      const routesMap = {
        "BAR-TH-101": `/devis/BAR-TH-101/${this.devisObject.data.devis.id}`,
        "BAR-EN-101": `/devis/BAR-EN-101/${this.devisObject.data.devis.id}`,
        "BAR-EN-102": `/devis/BAR-EN-102/${this.devisObject.data.devis.id}`,
        "BAR-EN-103": `/devis/BAR-EN-103/${this.devisObject.data.devis.id}`,
        "BAR-TH-112": `/devis/BAR-TH-112/${this.devisObject.data.devis.id}`,
        "BAR-TH-113": `/devis/BAR-TH-113/${this.devisObject.data.devis.id}`,
        "BAR-TH-171": `/devis/BAR-TH-171/${this.devisObject.data.devis.id}`,
        "BAR-TH-143": `/devis/BAR-TH-143/${this.devisObject.data.devis.id}`,
      };

      const path = routesMap[this.devisObject.data.devis.type_devis];
      if (path) {
        this.$router.push({ path });
      }

      this.updateCreationEntreprise(false);
    })
    .catch(() => {
      this.editLoader = false;
    });
},
      download() {
        if (this.pdfInfo.document.is_signed == "stored")
          window.open(this.pdfInfo.pdf, "_blank");
        else window.open(this.downloadUrl + this.pdfInfo.pdf, "_blank");
      },
  
      async fetchDevisObject(id) {
      
          this.devisObject = await this.getoneMonodevis(id);
          if (this.devisObject.data && this.devisObject.data.devis) {
            this.devisObject.data.devis.brouillon = 1;
          }
     
          this.rest = this.calculeRest();
  
          this.devisObjectLoaded = true;
       
      },
      async getPaiement(devis_ID) {
        try {
          const response = await axios.get(domain + `/devis_moyens_paiement`, {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
            params: {
              devis_id: devis_ID,
            },
          });
  
          return response.data.data;
        } catch (error) {
          console.error("Failed to fetch payment state:", error);
          throw new Error(
            error.response?.data?.message ||
              "An error occurred while fetching the payment information."
          );
        }
      },
      formatDate(date) {
        return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY');
      },
      async handleRouteChange() {
        const id = this.$route.params.id;
  
        if (id) {
      
            this.editLoader = true;
            await this.fetchDevisObject(id);
  
            if (this.devisObject && Object.keys(this.devisObject).length > 0) {
              this.editLoader = false;
            } else {
              this.editLoader = true;
            }
         
        } else {
          this.loadSavedDevisObject();
          if (this.devisObject && Object.keys(this.devisObject).length > 0) {
            this.editLoader = false;
          } else {
            this.editLoader = true;
          }
        }
      },
    },
   async created() {
      this.editLoader = true;
      this.handleRouteChange();
      const devi_ID = this.$route.params.id;
      try {
      
        const data = await this.getPaiement(devi_ID);
        this.moyens_paiement = data; 
      } catch (error) {
        console.error("Échec de la récupération des moyens de paiement :", error);
      }
    },
  
  };
  </script>
    <style lang="scss" scoped>
        .truncated-desc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; 
}

.read-toggle {
  color: blue;
  cursor: pointer;
  margin-left: 5px;
}

        .custom-dropdown .dropdown-menu {
          box-shadow: none !important;
          margin-right: 50px;
          left: auto;
          right: 0;
        }
        .dropdown-menu.show {
          display: block;
          margin-right: 120px;
        }
        .loding {
          height: 100% !important;
        }
        .brouillon {
          border-radius: 25px;
        }
        .bg-color {
          border-radius: 5px;
        
          background-color: white;
        }
        div {
          font-size: 13px;
        }
        .divborder {
          position: relative;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
          align-items: center;
          overflow: hidden;
          color: #28367a;
          width: 100%;
        }
        .divborder::after,
        .divborder::before {
          content: "";
          -ms-flex-preferred-size: 0;
          flex-basis: 0;
          -ms-flex-positive: 1;
          flex-grow: 1;
          max-width: 100%;
          height: 2px;
          background-color: #000000;
        }
        
        .justify {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }
        b {
          color: #28367a;
        }
        .red {
          font-weight: bold;
          color: #e4261a;
        }
        .part {
          padding: 8px 0;
          span {
            font-size: 14px;
            font-weight: 500;
            color: #515151;
            word-wrap: break-word;
          }
          .ml {
            margin-left: 500px;
          }
          .cadre {
            background-color: #f8f8f8;
            margin-top: 1%;
            margin-bottom: 1%;
            box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
            padding: 13px;
            height: 350px;
          }
          .left {
            @media only screen and (max-width: 1000px) {
              width: 100%;
            }
            width: 30%;
          }
        
          .right {
            @media only screen and (max-width: 1000px) {
              width: 100%;
            }
            width: 23%;
            p {
              float: right;
              padding: 8px;
              background-color: #d0cccc;
              color: #000;
              width: 50%;
              font-weight: 600;
              text-align: center;
              margin: 0 0 4px;
            }
          }
        
          .part-title {
            color: #28367a;
            font-weight: 700;
            margin-right: 10px;
          }
        
          ul {
            list-style: none;
        
            li {
              @media only screen and (max-width: 1000px) {
                justify-content: space-between;
              }
              display: flex;
              align-items: center;
              color: #515151;
        
              span {
                display: block;
                width: 174px;
                font-size: 14px;
                white-space: nowrap;
              }
        
              .title {
                @media only screen and (max-width: 900px) {
                  font-size: 12px;
                }
                color: #393939;
                font-weight: 600;
                font-size: 14px;
                margin-right: 10px;
              }
            }
        
            .traveauxtitle {
              @media only screen and (max-width: 900px) {
                padding: 0;
              }
              padding: 0 15px;
            }
          }
          .half-width {
            flex: 0 0 25%;
          }
          .flexEnd {
            display: flex;
            justify-content: space-between;
            .sousTraitant {
              p {
                color: #2167d9;
              }
            }
            .subtable {
              .complet {
                @media only screen and (max-width: 1000px) {
                  margin-right: 10%;
                  width: 100%;
                }
                border-collapse: separate;
                margin-right: 10%;
                width: 100%;
                height: 32px;
                text-align: center;
                font-size: 12px;
                font-weight: bold;
                tr {
                  height: 24px;
                }
        
                .thead {
                  @media only screen and (max-width: 1000px) {
                    padding: 6px !important;
                  }
                  background-color: #6472b3;
                  color: #fff;
                  max-width: 118px;
                  height: 32px;
                }
                td {
                  color: #000;
                  background-color: #e9eaf2;
                  width: 118px;
                }
              }
            }
          }
          .tabledonne {
            overflow-x: auto;
            margin-bottom: 4px;
          }
          table {
            border-collapse: separate;
            th {
              font-weight: 400;
              font-size: 12px;
              background-color: #6472b3;
              padding: 4px;
              color: #fff;
              border: 1px solid #6472b3;
              text-align: center;
            }
            td {
              text-align: center;
              padding: 4px;
              background-color: #e9eaf2;
              .libblock {
                font-weight: 800;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            tr {
              height: 24px;
            }
          }
        }
        .spinner-border {
          width: 1.2rem;
          height: 1.2rem;
        }
        .groupEdit {
          border: 1px solid #707070;
          padding: 8px;
          border-radius: 5px;
          margin-bottom: 16px;
          box-shadow: 0 0 2px 0 #707070;
        
          .entete {
            font-weight: 400;
            font-size: 12px;
            background-color: #28367a;
            padding: 4px;
            color: #fff;
            text-align: center;
            margin-bottom: 4px;
            display: flex;
            justify-content: space-between;
          }
          .action {
            display: flex;
            align-content: center;
            .trash,
            .ajout {
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 6px;
              color: #fff;
              height: 30px;
              width: 40%;
              margin: auto auto 15px auto;
            }
            .trash {
              background-color: #e4261a;
            }
          }
        }
        
        .textstyle {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      
        
        .inner-container .page-header button {
          margin-bottom: 0 !important;
          color: #fff;
          width: 0% !important;
          height: 43px;
        }
        .dropdown-btn {
          background-color: #28367a;
          text-align: center;
          padding: 10px 80px;
          margin-left: 20px;
          margin-right: 12px;
          border: none;
          cursor: pointer;
          border-radius: 5px;
        }
        .title{
          font-size: 18px;
              font-weight: 800;
              border: none;
              border-radius: 5px;
              box-shadow: 1px 1px 4px 0px #15223214;
        }
        .dropdown-content {
          cursor: pointer;
          display: none;
          position: absolute;
          top: 100%; /* Juste en dessous du bouton */
          left: 50%; /* Centre horizontalement par rapport au bouton */
          transform: translateX(-50%); /* Centre exactement le menu */
          background-color: #f9f9f9;
          min-width: 200px; /* Largeur minimale du menu */
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          z-index: 1;
        }
        .dropdown-content a {
          text-align: center;
          color: black;
          padding: 12px 16px;
          text-decoration: none;
          display: block;
        }
        .sort-arrow {
          cursor: pointer;
          color: grey;
          padding: 0 5px;
          font-size: 14px;
        }
        
        .sort-arrow:hover {
          color: white;
        }
        
        .active-sort {
          color: white;
          font-weight: bold;
        }
        
        .piece {
          background-color: #28367a;
          color: #fff;
          padding: 0px 15px;
          border-radius: 5px 5px 0 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
        
          div:first-child {
            display: flex;
            align-items: center;
            overflow: hidden;
          }
        
          p {
            margin: 0;
            font-size: 14px;
            margin-right: 8px;
            overflow: hidden;
          }
          .icone-down-up {
            display: flex;
            align-items: center;
            p,
            span {
              @media only screen and (max-width: 1000px) {
                display: none;
              }
            }
        
            button {
              background-color: transparent !important;
              border: transparent;
              box-shadow: none;
            }
        
            span {
              font-size: 14px;
              background-color: #6472b3;
              border-radius: 4px;
              padding: 2px 12px;
            }
          }
        }
        
        .group_financement {
          width: 100%;
          padding: 8px 12px;
          background-color: #eef1fd;
          border-radius: 5px;
        }
        .svg-inline--fa {
        
            margin: inherit;
        }
        .grey {
          background-color: #f5f6f8;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          width: 32px;
          margin: 0 auto;
        }
        
        form .form-group {
          max-width: none !important;
        }
        
        .desclass {
          margin-top: 9px;
        }
        .cours {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px;
          p {
            margin: 0;
            color: #fff;
            background-color: #d0cccc;
            width: 81px;
            height: 24px;
            font-size: 13px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        
          button {
            font-size: 14px;
          }
        }
        .content {
          .content-header {
            .input-group {
              @media only screen and (max-width: 1000px) {
                width: 59%;
              }
              width: 80%;
              position: relative;
              margin-bottom: 0;
              .icon-search {
                @media only screen and (max-width: 1000px) {
                  display: none;
                }
                overflow: visible;
                position: absolute;
                left: 15px;
                top: 10px;
                color: #515151;
              }
        
              .icon-balance {
                cursor: pointer;
                overflow: visible;
                position: absolute;
                right: 15px;
                top: 10px;
                color: #28367a;
                outline: 0;
        
                .balance {
                  color: #28367a;
                }
              }
              .form-control::placeholder {
                color: #515151;
                font-size: 12px;
              }
            }
        
            .filtre {
              @media only screen and (max-width: 1000px) {
                width: 39%;
              }
              width: 19%;
              background-color: #28367a;
              color: #fff;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 8px 4px;
              border-radius: 5px;
              font-size: 12px;
            }
          }
        
          & p {
            font-size: 12px;
            font-weight: 500;
            margin: 8px 15px 0 0;
          }
        }
        
        .body {
          padding: 15px;
          box-shadow: 1px 1px 24px #00000019;
          max-height: 60vh;
          overflow: auto;
        
          ul {
            list-style: none;
            & li {
              padding: 8px 0;
              cursor: pointer;
              background-color: #f8f8f8;
              margin-bottom: 4px;
              color: #000;
              border: none;
              box-shadow: none;
              transition: all 0.2s ease;
              text-align: center;
        
              &:hover {
                background-color: #28367a;
                color: #fff;
              }
            }
          }
        }
        
        .w-100 {
          background-color: #f8f8f8;
          padding: 4px;
          overflow-x: auto;
        }
        
        body {
          background: #fafdff;
        }
        .justify {
          padding: 13px;
          .left {
            h2 {
              color: #28367a;
              font-weight: 700;
              margin-bottom: 0px;
            }
            .entrepriseinfo {
              color: #000;
              font-weight: 600;
            }
            .title {
              color: #28367a;
              font-weight: 700;
            }
            .adressinfo {
              display: grid;
            }
            .info {
              color: #515151;
            }
            .block {
              margin-top: 10px;
            }
          }
          .right {
            .span {
              color: #515151;
            }
            h2 {
              color: #28367a;
              font-weight: 700;
            }
            .adressinfo {
              display: grid;
            }
            .info {
              color: #515151;
            }
            .entrepriseinfo {
              color: #000;
              font-weight: 600;
            }
          }
        }
        
        .divborder {
          position: relative;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
          align-items: center;
          overflow: hidden;
          color: #28367a;
          width: 100%;
          p {
            margin: 0px 10px;
          }
        }
        .divborder::after,
        .divborder::before {
          content: "";
          -ms-flex-preferred-size: 0;
          flex-basis: 0;
          -ms-flex-positive: 1;
          flex-grow: 1;
          max-width: 100%;
          height: 1px;
          background-color: #d8d8d8;
        }
        .part .table td.blocklibdesc {
          text-align: left;
        
          .descblock {
            white-space: normal;
            max-width: 400px;
            height: 72px;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.575;
            position: relative;
           
          }
          .read {
            bottom: -2px;
            right: 0;
            display: inline-block;
            font-size: 12px;
            font-weight: 800;
            color: #28367a;
          }
        }
        
        .detailClient {
          background-color: #fff;
          box-shadow: 1px 1px 24px #00000019;
          .clientTop {
            padding: 16px 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            border-bottom: 1px solid #707070;
        
            & .prodimg {
              max-width: 185px;
              max-height: 231px;
            }
        
            .star {
              color: #ffc400;
              position: absolute;
              top: 12px;
              right: 8px;
              font-size: 30px;
            }
          }
        
          .clientMid {
            padding: 16px 8px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            border-bottom: 1px solid #707070;
            & > div {
              width: 50%;
            }
          }
        
          .clientBot {
            padding: 16px 8px;
          }
        
          & h4 {
            color: #28367a;
            font-size: 18px;
            font-weight: 600;
            margin: 0;
          }
        
          & p {
            color: #515151;
            font-size: 16px;
            font-weight: 500;
          }
        }
        .filtre {
          display: flex;
          align-items: center;
          gap: 6px;
        
          .productTrie {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
            font-weight: 500;
            overflow: hidden;
            padding: 4px 8px;
            border-radius: 32px;
            background-color: #34c38f;
            color: #fff;
            margin: 2px 0 !important;
        
            .icons {
              cursor: pointer;
              width: 18px;
              height: 18px;
              margin-left: 4px;
            }
          }
        }
        
        .justify {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }
        
        .part {
          padding: 8px 0;
          span {
            font-size: 14px;
            font-weight: 500;
            color: #515151;
            word-wrap: break-word;
          }
          .remarqueblock {
            margin-bottom: 5px;
          }
          .cadre {
            background-color: #f8f8f8;
            margin-top: 1%;
            margin-bottom: 1%;
            box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
            padding: 13px;
          }
          .left {
            @media only screen and (max-width: 1000px) {
              width: 100%;
            }
            width: 30%;
          }
        
          .right {
            @media only screen and (max-width: 1000px) {
              width: 100%;
            }
            width: 23%;
            p {
              float: right;
              padding: 8px;
              background-color: #d0cccc;
              color: #000;
              width: 50%;
              font-weight: 600;
              text-align: center;
              margin: 0 0 4px;
            }
          }
        
          .fact {
            font-size: 16px;
            color: #28367a;
          }
        
          h2,
          h3 {
            font-weight: 600;
          }
        
          h2 {
            font-size: 16px;
          }
          h3 {
            color: #515151;
            font-size: 16px;
            margin: 0;
          }
       
          ul {
            list-style: none;
        
            li {
              @media only screen and (max-width: 1000px) {
                justify-content: space-between;
              }
              display: flex;
              align-items: center;
              color: #515151;
        
              span {
                display: block;
                width: 174px;
                font-size: 14px;
                white-space: nowrap;
              }
        
              .title {
                @media only screen and (max-width: 900px) {
                  font-size: 12px;
                }
                color: #393939;
                font-weight: 600;
                font-size: 14px;
                margin-right: 10px;
              }
            }
        
            .traveauxtitle {
              @media only screen and (max-width: 900px) {
                padding: 0;
              }
              padding: 0 15px;
            }
          }
          .flexEnd {
            display: flex;
            justify-content: space-between;
            .sousTraitant {
              p {
                color: #2167d9;
              }
            }
            .subtable {
              .complet {
                @media only screen and (max-width: 1000px) {
                  margin-right: 10%;
                  width: 100%;
                }
                border-collapse: separate;
                margin-right: 10%;
                width: 100%;
                height: 32px;
                text-align: center;
                font-size: 12px;
                font-weight: bold;
                tr {
                  height: 24px;
                }
        
                .thead {
                  @media only screen and (max-width: 1000px) {
                    padding: 6px !important;
                  }
                  background-color: #6472b3;
                  color: #fff;
                  max-width: 118px;
                  height: 32px;
                }
                td {
                  color: #000;
                  background-color: #e9eaf2;
                  width: 118px;
                }
              }
            }
          }
          .tabledonne {
            overflow-x: auto;
            margin-bottom: 4px;
          }
          table {
            border-collapse: separate;
            th {
              font-weight: 400;
              font-size: 12px;
              background-color: #6472b3;
              padding: 4px;
              color: #fff;
              border: 1px solid #6472b3;
              text-align: center;
            }
            td {
              text-align: center;
              padding: 4px;
              background-color: #e9eaf2;
              .libblock {
                font-weight: 800;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            tr {
              height: 24px;
            }
          }
        }
        .group {
          @media only screen and (max-width: 1000px) {
            overflow: hidden;
            padding: 8px;
          }
          box-shadow: 0px 2px 6px #00000019;
          background-color: #fff;
          border-radius: 0 0 5px 5px;
          margin-bottom: 15px;
          .part {
            overflow: auto;
            padding: 15px;
            margin-bottom: 0;
          }
        }
       
        .flexEndTotale {
          display: flex;
          justify-content: flex-end;
          .subtableTotale {
            .completTotale {
              @media only screen and (max-width: 1000px) {
                margin-right: 10%;
                width: 100%;
              }
              border-collapse: separate;
              margin-right: 10%;
              width: 100%;
              height: 32px;
              text-align: center;
              font-size: 12px;
              font-weight: bold;
              tr {
                height: 24px;
              }
        
              .theadTotale {
                @media only screen and (max-width: 1000px) {
                  padding: 6px !important;
                }
                background-color: #28367a;
                color: #fff;
                max-width: 118px;
                height: 32px;
              }
              td {
                color: #000;
                background-color: #e9eaf2;
                width: 135px;
              }
            }
          }
        }      
        .textstyle {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
     
    div {
      font-size: 13px;
    }
    .divborder {
      position: relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      overflow: hidden;
      color: #28367a;
      width: 100%;
      height: 3px;
    }
  
    .divborder::after,
    .divborder::before {
      content: "";
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
      height: 2px;
      background-color: #000000;
    }
    
    .justify {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .brouillon {
      border-radius: 25px;
    }
    b {
      color: #28367a;
    }
    .red {
      font-weight: bold;
      color: #e4261a;
    }
    .part {
      padding: 8px 0;
      span {
        font-size: 14px;
        font-weight: 500;
        color: #515151;
        word-wrap: break-word;
      }
    
      .cadre {
        background-color: #f8f8f8;
        margin-top: 1%;
        margin-bottom: 1%;
        box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
        padding: 13px;
        height: 350px;
      }
      .left {
        @media only screen and (max-width: 1000px) {
          width: 100%;
        }
        width: 30%;
      }
    
      .right {
        @media only screen and (max-width: 1000px) {
          width: 100%;
        }
        width: 23%;
        p {
          float: right;
          padding: 8px;
          background-color: #d0cccc;
          color: #000;
          width: 50%;
          font-weight: 600;
          text-align: center;
          margin: 0 0 4px;
        }
      }
    
  
    
      ul {
        list-style: none;
    
        li {
          @media only screen and (max-width: 1000px) {
            justify-content: space-between;
          }
          display: flex;
          align-items: center;
          color: #515151;
    
          span {
            display: block;
            width: 174px;
            font-size: 14px;
            white-space: nowrap;
          }
    
          .title {
            @media only screen and (max-width: 900px) {
              font-size: 12px;
            }
            color: #393939;
            font-weight: 600;
            font-size: 14px;
            margin-right: 10px;
          }
        }
    
        .traveauxtitle {
          @media only screen and (max-width: 900px) {
            padding: 0;
          }
          padding: 0 15px;
        }
      }
      .half-width {
        flex: 0 0 25%;
      }
      .flexEnd {
        display: flex;
        justify-content: space-between;
        .sousTraitant {
          p {
            color: #2167d9;
          }
        }
        .subtable {
          .complet {
            @media only screen and (max-width: 1000px) {
              margin-right: 10%;
              width: 100%;
            }
            border-collapse: separate;
            margin-right: 10%;
            width: 100%;
            height: 32px;
            text-align: center;
            font-size: 12px;
            font-weight: bold;
            tr {
              height: 24px;
            }
    
            .thead {
              @media only screen and (max-width: 1000px) {
                padding: 6px !important;
              }
              background-color: #6472b3;
              color: #fff;
              max-width: 118px;
              height: 32px;
            }
            td {
              color: #000;
              background-color: #e9eaf2;
              width: 118px;
            }
          }
        }
      }
      .tabledonne {
        overflow-x: auto;
        margin-bottom: 4px;
      }
      table {
        border-collapse: separate;
        th {
          font-weight: 400;
          font-size: 12px;
          background-color: #6472b3;
          padding: 4px;
          color: #fff;
          border: 1px solid #6472b3;
          text-align: center;
        }
        td {
          text-align: center;
          padding: 4px;
          background-color: #e9eaf2;
          .libblock {
            font-weight: 800;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        tr {
          height: 24px;
        }
      }
    }
    .spinner-border {
      width: 1.2rem;
      height: 1.2rem;
    }
    .groupEdit {
      border: 1px solid #707070;
      padding: 8px;
      border-radius: 5px;
      margin-bottom: 16px;
      box-shadow: 0 0 2px 0 #707070;
    
      .entete {
        font-weight: 400;
        font-size: 12px;
        background-color: #28367a;
        padding: 4px;
        color: #fff;
        text-align: center;
        margin-bottom: 4px;
        display: flex;
        justify-content: space-between;
      }
      .action {
        display: flex;
        align-content: center;
        .trash,
        .ajout {
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 6px;
          color: #fff;
          height: 30px;
          width: 40%;
          margin: auto auto 15px auto;
        }
        .trash {
          background-color: #e4261a;
        }
      }
    }
    
    .textstyle {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    </style>